<template>
  <list-template
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
    @onAdd="$router.push('./add')"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/exam/list-exam-enroll-conf')
    },

    handleClick(row, handle) {
      const { type } = handle
      if(type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }
    }
  },
  data() {
    return {
      tableConfig: [
        { prop: 'school_name', label: '学校' },
        { prop: 'city_name', label: '学校所属城市' },
        { prop: 'score_type', label: '当地计分方式', render: (row) => row.score_type ? '等级' : '分数' },
        { prop: 'first_line', label: '第一档录取分', render: (row) => row.score_type ? row.first_line_level : row.first_line },
        { prop: 'second_line', label: '第二档录取分', render: (row) => row.score_type ? row.second_line_level : row.second_line },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
